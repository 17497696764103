import React, { useContext, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { Icon } from '@navent-jobs/ui-kit/components/icon'

import { CorazonContainer, Container } from './mixins'

import CorazonAnimado from '../../../corazon-animado'
import ModalNotInterested from '../../../modals/ModalNotInterested'

export const ActionButtons = ({
  index,
  tipoAviso,
  handleSavePosting,
  savePosting,
  disableSavePosting,
  showSavePosting,
  showNotInterested = false,
  id,
  handleNotInterested,
}) => {
  const { colors } = useContext<{ colors }>(ThemeContext)
  const [visible, setVisible] = useState(false)

  const onclick = () => {
    setVisible(false)
    if (handleNotInterested) {
      setTimeout(() => {
        handleNotInterested(id)
      }, 750)
    }
  }

  return (
    <>
      {showSavePosting && tipoAviso !== 'bumeran' && (
        <CorazonContainer>
          <CorazonAnimado
            index={index}
            disabled={disableSavePosting}
            savePosting={savePosting}
            onClick={() => handleSavePosting()}
          />
        </CorazonContainer>
      )}
      {showNotInterested && (
        <>
          <Container onClick={() => setVisible(true)}>
            <Icon size="18" color={colors.grey72} name="icon-light-thumbs-down" />
          </Container>
          <ModalNotInterested avisoId={id} visible={visible} setVisible={setVisible} onClick={onclick} />
        </>
      )}
    </>
  )
}

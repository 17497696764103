import styled from 'styled-components'
import { Button } from '@navent-jobs/ui-kit/components/button'

export const Page = styled('div')`
  background-color: ${({ theme }) => theme.colors.grey0};
  padding: 24px 0px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    padding: 48px 0px;
  }
`

export const MainWrapper = styled.div`
  width: 100%;
  margin: 0 auto;

  @media (max-width: ${({ theme }) => theme.screen.mdMax}) {
    max-width: auto;
  }

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    max-width: 624px;
  }
`

export const HeaderWrapper = styled.div`
  margin-bottom: 24px;
`

export const FormGroup = styled('div')`
  margin-bottom: 4px;
`

export const HelperRequired = styled('p')`
  font-size: 12px;
  margin-bottom: 24px;

  &:before {
    content: '*';
    font-size: 14px;
    color: red;
    margin-right: 5px;
  }
`

export const MessageError = styled('p')`
  color: red;
  font-size: 12px;
`

export const Label = styled('div')`
  &:after {
    content: '*';
    font-size: 12px;
    color: red;
    margin-left: 3px;
    vertical-align: 3px;
  }
`

export const TipoYNumeroWrapper = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
`

export const TipoDocumentoWrapper = styled('div')`
  width: 100%;
  max-width: 143px;
  flex-grow: 1;
`

export const NumeroWrapper = styled('div')`
  flex-grow: 1;
  margin-left: 12px;
`

export const LinkEmpresasWrapper = styled.div`
  margin: 20px auto 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SocialLoadedButton = styled(Button)`
  width: 100%;
  height: 40px;
  margin-bottom: 24px;
  max-width: 330px;
`

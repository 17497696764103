import styled from 'styled-components'
import { Title } from '@navent-jobs/ui-kit/components/title'

export const ElementsContainer = styled.div<{ theme }>`
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  padding: 24px 16px;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.grey0};
  border-radius: 8px;
  margin-bottom: 24px;

  @media (min-width: ${props => props.theme.screen.lgMin}) {
    padding: 72px 16px;
    flex-direction: row;
    text-align: left;
  }
`

export const ImageForEmptyState = styled.img<{ theme }>`
  width: 100%;
  max-width: 288px;
  margin: 0 auto 40px;

  @media (min-width: ${props => props.theme.screen.lgMin}) {
    max-width: 320px;
    margin: 0px 24px 0px 0px;
  }

  @media (min-width: ${props => props.theme.screen.xlMin}) {
    max-width: 320px;
  }
`

export const TextContainer = styled.div`
  @media (min-width: ${props => props.theme.screen.lgMin}) {
    max-width: 400px;
  }
`

export const Heading = styled(Title)<{ theme }>`
  font-size: 18px;
  line-height: 24px;
  color: ${props => props.theme.colors.secondary.normal};
  font-weight: 600;

  @media (min-width: ${props => props.theme.screen.xlMin}) {
    font-size: 32px;
    line-height: 40px;
  }
`

export const Subtitle = styled.p<{ theme }>`
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 24, 0.84);
  margin: 12px 0px 0px;

  @media (min-width: ${props => props.theme.screen.lgMin}) {
    font-size: 18px;
    line-height: 24px;
    margin: 24px 0px 0px;
  }
`

import styled from 'styled-components'
import { Link } from '@navent-jobs/ui-kit/components/link'
import { Title } from '@navent-jobs/ui-kit/components/title'

export const CustomHyperlink = styled.a`
  color: ${({ theme }) => theme.colors.grey84};

  :hover {
    color: ${({ theme }) => theme.colors.grey84};
    text-decoration: none;
  }
`

export const Card = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey0};
  border: 1px ${({ theme }) => theme.colors.grey8};
  padding: 0 12px 12px;
  margin: 0px 0px 16px 0px;
  border-radius: 8px;
  position: relative;
  cursor: pointer;

  &:last-child {
    margin: 0px;
  }

  &:hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  }

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    max-width: 413px;
    margin: 0px 24px 0px 0px;

    &:last-child {
      margin-right: 0px;
    }
  }
`

export const CardLogo = styled.div`
  height: 168px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
`

export const CardContent = styled.div`
  margin-bottom: 24px;
`
export const CustomTitle = styled(Title)`
  line-height: 20px;
  color: ${({ theme }) => theme.colors.secondary.normal};
  margin-bottom: 8px;
`

export const Body = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
`

export const CardFooter = styled.div`
  width: calc(100% - 24px);
  display: flex;
  justify-content: ${({ showPrice, acquired }) => (showPrice || acquired ? 'space-between' : 'end')};
  position: absolute;
  bottom: 12px;
  left: 12px;
`

export const Price = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.grey60};
  margin: 0px;
`

export const CustomLink = styled(Link)`
  font-size: 12px;
  line-height: 16px;
  display: flex;
  padding: 0px;
`

export const ProductAcquired = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.secondary.normal};
  margin: 0px;
`

export const Tag = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  padding: 4px 6px;
  border-radius: 4px;
  top: 12px;
  left: 12px;
  background-color: ${({ theme }) => theme.colors.primary.normal};
  color: white;
`

export const CardOverlay = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0.9;
  visibility: none;
  transition: 0.5s ease;
  background-color: white;
  text-align: center;
  padding-top: 35%;
  border-radius: 8px;

  @media (min-width: ${({ theme }) => theme.screen.Min}) {
    padding-top: 14%;
  }
  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    padding-top: 35%;
  }
`

const login = {
  IngresaCuenta: 'Ingresá a tu cuenta',
  emailPlaceholder: 'Ingresá tu email',
  IngresaEmailValido: 'Ingresá un email válido.',
  IngresContrasena: 'Ingresá tu contraseña',
  olvideContrasena: 'Olvidé mi contraseña',
  IngresaContrasenaValida: 'Ingresá una contraseña válida.',
  sinCuenta: '¿No tenés cuenta?',
  registrate: 'Registrate como candidato',
  linkEmpresas: 'Ingresá como empresa',
}

export default login

import styled from 'styled-components'
import { Link } from '@navent-jobs/ui-kit/components/link/Link'

export const CustomLink = styled(Link)`
  min-width: 200px;
`

export const AlreadyReviewedText = styled.p`
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.grey48};
  font-weight: 600;
`

export const Title = styled.p`
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.secondary.normal};
  font-weight: 600;
  margin-bottom: 16px;
`

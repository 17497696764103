import styled from 'styled-components'
import { Title } from '@navent-jobs/ui-kit/components/title'

export const EmptyStateComponent = styled('div')`
  background-color: white;
  text-align: center;
  padding: 24px 15px 32px;
  margin-bottom: 24px;
  border-radius: 8px;
  word-break: break-word;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    padding: 24px 40px 48px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  }

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    padding: 64px 118px 48px;
  }
`

export const Image = styled('img')`
  width: 100%;

  @media (min-width: ${({ theme }) => theme.screen.smMin}) {
    width: 100%;
    max-width: 294px;
  }

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    max-width: 260px;
  }

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    max-width: 330px;
  }
`

export const TextContainer = styled('div')`
  max-width: 230px;
  margin: 0 auto 12px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    max-width: inherit;
  }

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    max-width: 240px;
    text-align: left;
    margin: 0px;
  }

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    max-width: 290px;
  }
`

export const EmptyStateContainer = styled('div')`
  margin-bottom: 32px;

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    margin-bottom: 52px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    margin-bottom: 98px;
  }
`

export const Heading = styled(Title)`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.secondary.normal};
  line-height: 24px;
  margin-bottom: 12px;

  @media (min-width: ${({ theme }) => theme.screen.smMin}) {
    font-size: 24px;
    line-height: 32px;
  }

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    margin-bottom: 24px;
  }

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    font-size: 32px;
    line-height: 40px;
  }
`

export const Subtitle = styled('p')`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey84};
  line-height: 20px;

  @media (min-width: ${({ theme }) => theme.screen.smMin}) {
    font-size: 18px;
    line-height: 24px;
  }

  @media (min-width: ${({ theme }) => theme.screen.lgMin}) {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0px;
  }
`

import styled from 'styled-components'
import { Icon } from '@navent-jobs/ui-kit/components/icon'

export const ProgressBar = styled.div`
  width: 100%;
  height: 12px;
  background-color: #e4e4e4;
  align-self: center;
`

export const Filler = styled.div`
  width: ${({ fillPercentage }) => `${fillPercentage}%`};
  height: 12px;
  background-color: #fab306;
`

export const IdText = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.grey72};
  margin: 0;
  width: 8px;
  margin-right: 5px;
`

export const ValueText = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grey48};
  margin: 0;
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 8px;
`

export const IconStar = styled(Icon)`
  align-self: center;
  margin-right: 5px;
`

export const TextContainer = styled.div`
  width: 40px;
  margin-left: 4px;
`

export const Wrapper = styled.div`
  margin-left: 0;
`

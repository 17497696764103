import React from 'react'
import { isEmpty } from 'lodash'
import ReactHtmlParser from 'react-html-parser'
import Text from '@navent-jobs/ui-kit/components/text'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { planConfig } from '../../constants/planConfig'
import { InfoCol } from '../../mixins'
import {
  MainInfoCard,
  Company,
  CardHeader,
  LogoWrapper,
  Logo,
  CustomText,
  NewTag,
  Title,
  Description,
  DataRowTitle,
  CardBody,
  HeaderCol,
  HeaderContainer,
  DateRow,
  HeaderColContainer,
  DataColMobile,
  Rating,
} from './mixins'

const showNewTag = (estadoPostulacion, isRead, tipoAviso) => {
  return !(estadoPostulacion || isRead) && planConfig[tipoAviso].showNewTag
}

const defineCompanyName = ({ confidencial, tipoAviso, empresa, mobileFormat }) => {
  if (confidencial) {
    return (
      <Company mobileFormat={mobileFormat}>
        <Text size="sm" fontWeight="semibold" as="h3">
          Confidencial
        </Text>
      </Company>
    )
  }

  return (
    planConfig[tipoAviso].showCompanyName && (
      <Company mobileFormat={mobileFormat}>
        <Text size="sm" fontWeight="semibold" as="h3">
          {empresa}
        </Text>
      </Company>
    )
  )
}

const makeTitleRow = props => {
  const {
    isDesktop,
    tipoAviso,
    empresa,
    avisoNuevo,
    cantidadDeDias,
    estadoPostulacion,
    isRead,
    confidencial,
    promedioEmpresa,
    mobileFormat,
  } = props

  const Wrapper = ({ children }) => (!isDesktop || mobileFormat ? <DateRow>{children}</DateRow> : <>{children}</>)

  return (
    <DataRowTitle mobileFormat={mobileFormat}>
      {defineCompanyName({ confidencial, tipoAviso, empresa, mobileFormat })}

      {isDesktop && !mobileFormat && planConfig[tipoAviso].showCompanyName && empresa && (
        <Company mobileFormat={mobileFormat}>
          <Text size="sm" fontWeight="semibold">
            -
          </Text>
        </Company>
      )}

      <Wrapper>
        {!confidencial && promedioEmpresa && (
          <>
            <Rating>{promedioEmpresa.toFixed(1)}</Rating>
            <Icon name="icon-bold-star" color="#FAB306" size="14" />
            <Company>
              <Text size="sm" fontWeight="semibold">
                -
              </Text>
            </Company>
          </>
        )}

        {showNewTag(estadoPostulacion, isRead, tipoAviso) && avisoNuevo && <NewTag>Nuevo</NewTag>}
        <CustomText>{cantidadDeDias}</CustomText>
      </Wrapper>
    </DataRowTitle>
  )
}

const showDescription = (tipoAviso, detalle, mobileFormat, clamp) => {
  return (
    planConfig[tipoAviso].showDescription &&
    !isEmpty(detalle) && (
      <CardBody>
        <Description clamp={clamp} mobileFormat={mobileFormat}>
          <Text size="sm">{ReactHtmlParser(detalle)}</Text>
        </Description>
      </CardBody>
    )
  )
}

const Component = ({
  tipoAviso,
  confidencial,
  logoURL,
  estadoPostulacion,
  estadoPostulacionEspera,
  isDesktop,
  empresa,
  cantidadDeDias,
  avisoNuevo,
  titulo,
  detalle,
  promedioEmpresa,
  isRead,
  mobileFormat,
  clamp,
  hideCompanyRow = false,
}) => {
  const isDesktopFormat = isDesktop && !mobileFormat
  const showLogo = !isEmpty(logoURL) && planConfig[tipoAviso].showLogo
  const showTitle = (
    <Title
      mobileFormat={mobileFormat}
      hideCompanyRow={hideCompanyRow}
      buttons={false}
      tipoAviso={tipoAviso}
      hasDescription={planConfig[tipoAviso].showDescription && !isEmpty(detalle)}
      withIcon={estadoPostulacion || estadoPostulacionEspera || isRead}
    >
      <Text size="md" fontWeight="semibold" variant="secondary" as="h3">
        {titulo}
      </Text>
    </Title>
  )

  return (
    <HeaderColContainer>
      <HeaderContainer mobileFormat={mobileFormat} tipoAviso={tipoAviso}>
        {!isDesktopFormat && <DataColMobile>{showTitle}</DataColMobile>}

        <MainInfoCard mobileFormat={mobileFormat}>
          <CardHeader
            hasCompanyName={planConfig[tipoAviso].showCompanyName && !confidencial}
            hasLogo={showLogo}
            hideCompanyRow={hideCompanyRow}
          >
            {showLogo && (
              <LogoWrapper>
                <Logo src={logoURL} />
              </LogoWrapper>
            )}

            <HeaderCol hasLogo={showLogo}>
              {isDesktopFormat && showTitle}
              {!hideCompanyRow &&
                makeTitleRow({
                  isDesktop,
                  tipoAviso,
                  empresa,
                  avisoNuevo,
                  cantidadDeDias,
                  estadoPostulacion,
                  confidencial,
                  promedioEmpresa,
                  isRead,
                  mobileFormat,
                })}
            </HeaderCol>
          </CardHeader>

          {isDesktopFormat && showDescription(tipoAviso, detalle, mobileFormat, clamp)}
        </MainInfoCard>
      </HeaderContainer>

      {!isDesktopFormat && (
        <DataColMobile>
          <InfoCol mobileFormat={mobileFormat}>{showDescription(tipoAviso, detalle, mobileFormat, clamp)}</InfoCol>
        </DataColMobile>
      )}
    </HeaderColContainer>
  )
}

export default Component

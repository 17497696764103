import React, { useContext, useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { ThemeContext } from 'styled-components'

const Corazon = ({ index, disabled, onClick, width = 24, height = 24 }) => {
  const { colors } = useContext<{ colors }>(ThemeContext)
  const heartRef = useRef<SVGSVGElement>(null)
  const sparkleGrowColors = ['#9E31E2', '#9E31E2', '#9E31E2', '#92E8C5', '#CDEB8E', '#2AD492', '#D79DF3']
  const sparkleMoveColors = ['#E187D2', '#E0A3FF', '#F5BB30', '#9ECA98', '#35A0F0', '#BADAB0', '#33B6E9']

  const followAnimation = tl => {
    tl.from(`#pinkDot${index}`, {
      duration: 1,
      fillOpacity: 1,
      attr: {
        r: 0,
      },
    })
      .set(`#pinkHeart${index}`, { fillOpacity: 1 })
      .set(
        `#greyHeart${index}`,
        {
          scale: 0,
          transformOrigin: '50% 100%',
        },
        '-=0.99'
      )
      .to(
        `#pinkDot${index}`,
        {
          duration: 1,
          fill: '#CD8FF7',
        },
        '-=1'
      )
      .to(
        `#hole${index}`,
        {
          duration: 1,
          attr: {
            r: 67,
          },
        },
        '-=0.5'
      )
      .from(
        `#pinkHeart${index}`,
        {
          duration: 1.6,
          scale: 0,
          alpha: 1,
          transformOrigin: '50% 50%',
          ease: 'back.out(1.2)',
        },
        '-=0.5'
      )
      .set(
        [`#sparkleGrowGroup${index}`, `#sparkleMoveGroup${index}`],
        {
          alpha: 1,
        },
        '-=1.5'
      )
      .to(
        `#sparkleGrowGroup${index}`,
        {
          duration: 1,
          scale: 1.5,
          transformOrigin: '50% 50%',
        },
        '-=1.5'
      )
      .to(
        `#sparkleMoveGroup${index}`,
        {
          duration: 1,
          scale: 1.2,
          transformOrigin: '50% 50%',
        },
        '-=1.5'
      )
      .staggerTo(
        `#sparkleGrowGroup${index} circle`,
        2,
        {
          attr: {
            r: 0,
          },
          cycle: {
            fill(i) {
              return sparkleGrowColors[i]
            },
          },
        },
        0,
        '-=0.9'
      )
      .staggerTo(
        `#sparkleMoveGroup${index} circle`,
        0.8,
        {
          attr: {
            r: 0,
          },
          cycle: {
            fill(i) {
              return sparkleMoveColors[i]
            },
          },
        },
        0,
        '-=2'
      )
  }

  useEffect(() => {
    gsap.set('svg', {
      visibility: 'visible',
    })

    const tl = gsap.timeline({ paused: true, onComplete: onClick })
    followAnimation(tl)

    if (heartRef.current) {
      heartRef.current.onclick = () => {
        if (!disabled) {
          tl.play(0)
        }
      }
    }

    tl.timeScale(4)

    // Cleanup on unmount
    return () => {
      tl.kill()
    }
  }, [])

  return (
    <svg
      ref={heartRef}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox={`0 0 ${width} ${height}`}
    >
      <defs>
        <mask id={`dotMask${index}`}>
          <circle id={`whiteDot${index}`} fill="#FFFFFF" cx={width / 2} cy={height / 2} r={width * 0.11} />
          <circle id={`hole${index}`} cx={width / 2} cy={height / 2} r="0" />
        </mask>
        <path
          id={`heart${index}`}
          d="M12 21C11.734 21 11.48 20.895 11.292 20.706L3.52505 12.926C1.48905 10.886 1.48905 7.567 3.52505 5.527C4.50805 4.543 5.82105 4 7.22005 4C8.61905 4 9.93205 4.543 10.915 5.527L12 6.614L13.084 5.528C14.068 4.543 15.381 4 16.78 4C18.179 4 19.492 4.543 20.475 5.527C22.511 7.567 22.511 10.886 20.476 12.926L12.708 20.707C12.52 20.895 12.266 21 12 21Z"
        />
        <path
          id={`heart-light${index}`}
          d="M7.2197 6C6.3557 6 5.5457 6.334 4.9397 6.941C3.6817 8.201 3.6817 10.252 4.9407 11.514L11.9997 18.585L19.0597 11.514C20.3187 10.252 20.3187 8.201 19.0597 6.941C17.8477 5.726 15.7117 5.728 14.4997 6.941L12.7077 8.736C12.3317 9.113 11.6677 9.113 11.2917 8.736L9.4997 6.94C8.8937 6.334 8.0847 6 7.2197 6ZM11.9997 21C11.7347 21 11.4797 20.895 11.2927 20.706L3.5247 12.926C1.4887 10.886 1.4887 7.567 3.5247 5.527C4.5087 4.543 5.8207 4 7.2197 4C8.6187 4 9.9317 4.543 10.9147 5.527L11.9997 6.614L13.0847 5.528C14.0687 4.543 15.3807 4 16.7807 4C18.1787 4 19.4917 4.543 20.4747 5.527C22.5117 7.567 22.5117 10.886 20.4757 12.926L12.7077 20.707C12.5197 20.895 12.2657 21 11.9997 21Z"
        />
      </defs>
      <use
        id={`greyHeart${index}`}
        xlinkHref={`#heart-light${index}`}
        fill={disabled ? colors.grey24 : colors.grey72}
      />
      <use id={`pinkHeart${index}`} xlinkHref={`#heart${index}`} fill={colors.primary.normal} fillOpacity="0" />

      <g mask={`url(#dotMask${index})`}>
        <circle
          id={`pinkDot${index}`}
          fill={colors.primary.normal}
          cx={width / 2}
          cy={height / 2}
          r={width * 0.11}
          fillOpacity="0"
        />
      </g>
      <g id={`sparkleGrowGroup${index}`} opacity="0">
        <circle fill="#91D1F9" cx="3" cy="16" r="1.20" />
        <circle fill="#91D1F9" cx="16" cy="4" r="1.20" />
        <circle fill="#8CE9C4" cx="15" cy="15" r="1.20" />
        <circle fill="#8CE9C4" cx="4" cy="3" r="1.20" />
        <circle fill="#F48DA6" cx="5" cy="14" r="1.20" />
        <circle fill="#CB8EF4" cx="13" cy="6" r="1.20" />
        <circle fill="#91D1F9" cx="17" cy="17" r="1.20" />
        <circle fill="#CB8EF4" cx="4" cy="12" r="1.20" />
        <circle fill="#F48DA6" cx="16" cy="14" r="1.20" />
        <circle fill="#91D1F9" cx="18" cy="18" r="1.20" />
        <circle fill="#91D1F9" cx="9" cy="3" r="1.20" />
      </g>
      <g id={`sparkleMoveGroup${index}`} opacity="0">
        <circle fill="#91D1F9" cx="10.1" cy="5.5" r="1.20" />
        <circle fill="#91D1F9" cx="8.3" cy="15.2" r="1.20" />
        <circle fill="#8CE9C4" cx="7.5" cy="6.4" r="1.20" />
        <circle fill="#8CE9C4" cx="13.1" cy="8.7" r="1.20" />
        <circle fill="#F48DA6" cx="11.5" cy="15.2" r="1.20" />
        <circle fill="#CB8EF4" cx="7.2" cy="14.2" r="1.20" />
        <circle fill="#CB8EF4" cx="12.3" cy="6.3" r="1.20" />
      </g>
    </svg>
  )
}
export default Corazon

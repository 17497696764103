import styled from 'styled-components'
import { Title } from '@navent-jobs/ui-kit/components/title'
import { Select } from '@navent-jobs/ui-kit/components/select'
import { Link } from '@navent-jobs/ui-kit/components/link'
import Image from '@navent-jobs/ui-kit/components/Image'

const dividerLine = (fullWidth: boolean, theme) =>
  `position: relative;
   
   &:after {
     content: "";
     width: ${fullWidth ? '100vw' : 'calc( 100% - 32px )'};
     max-width: ${fullWidth ? 'none' : '1156px'};
     height: 1px;
     background-color: rgba(0, 0, 24, 0.08);
     position: absolute;
     bottom: 0;
     left: 50%;
     transform: translate(-50%, 0);
   }

   @media (min-width: ${theme.screen.mdMin}) { 
     &:after {
       width: ${fullWidth ? '100vw' : 'calc( 100% - 56px )'};
     }
   }

   @media (min-width: ${theme.screen.lgMin}) { 
     &:after {
       width: ${fullWidth ? '100vw' : 'calc( 100% - 88px )'};
     }
   }
`

export const FooterComponent = styled.footer`
  background-color: white;
  text-align: center;
`

export const SocialNetworks = styled('div')<{}>`
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${props => dividerLine(false, props.theme)}
`

export const Form = styled.form``

export const FormInput = styled.input`
  background: none;
  border: none;
  font-family: Hind Vadodara;
  font-weight: 600;
  color: #00001899;
  font-size: 16px;

  &:hover {
    color: #000018;
  }
`

export const SocialNetworkLink = styled.a`
  margin: 0px 12px;

  &:first-child {
    margin: 0px 12px 0px 0px;
  }

  &:last-child {
    margin: 0px 0px 0px 12px;
  }

  &:hover {
    img {
      background-color: rgba(0, 0, 24, 0.6);
      border-radius: 50%;
    }
  }
`

export const SelectContainer = styled.div`
  width: 200px;
  background: transparent;
  margin-bottom: 32px;
  border-radius: 8px;

  @media (min-width: ${props => props.theme.screen.mdMin}) {
    margin-bottom: 0px;
  }

  .select__control .select__value-container {
    height: 24px;
  }
`

export const LinksLegalesContainer = styled.div`
  padding: 32px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${props => dividerLine(false, props.theme)}
`

export const LinksLegales = styled.div``

export const LinkLegal = styled(Link)`
  padding: 0;
  transition: color 0.3s ease-in-out;
  border: none;
`

export const SpanLegal = styled.span`
  line-height: 20px;
  display: block;
  padding-right: 15px;
  margin-bottom: 16px;
  position: relative;

  &:last-child {
    padding-right: 0px;
  }

  @media (min-width: ${props => props.theme.screen.mdMin}) {
    line-height: 40px;
    display: inline-block;
    margin-bottom: 0px;

    &:after {
      content: '';
      width: 6px;
      height: 2px;
      background-color: rgba(0, 0, 24, 0.6);
      position: absolute;
      top: 50%;
      right: 1px;
      transform: translate(-50%, 0);
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
`

export const Label = styled(Title)`
  font-weight: 600;
`

export const SelectCountries = styled(Select)`
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
`

export const FooterBottomContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 32px 0px;
  margin-bottom: ${({ showBottomNavBar }) => (showBottomNavBar ? '60px' : 0)};

  @media (min-width: ${props => props.theme.screen.mdMin}) {
    flex-direction: row;
    justify-content: space-evenly;
    padding: 48px 0px;
  }
`

export const CopyrightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${props => props.theme.screen.mdMin}) {
    justify-content: left;
  }
`

export const Copyright = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  color: ${props => props.theme.colors.grey84};
`

export const DataFiscal = styled('div')`
  margin-right: 16px;
`

export const DataFiscalImage = styled(Image)`
  width: 60px;
`

export const LinkJobint = styled.a`
  margin-bottom: 16px;
`

export const OptionFlag = styled(Image)`
  margin-right: 15px;
  vertical-align: -6px;
`

export const OptionContainer = styled.div`
  text-align: left;
`

export const OptionLabel = styled.span`
  font-weight: 400;
`

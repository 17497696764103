import React from 'react'
import logoZonajobs from '@navent-jobs/ui-kit/assets/img/logos/zonajobsNaranja.svg'
import logoBumeran from '../../../../assets/images/logos/bumeran.svg'
import logoGPTW from '../../../../assets/images/calificar-empresas/GPTW_Logo.svg'
import { DataIcon } from '../../mixins'
import {
  LastInfoCol,
  DataInfoDisability,
  InterPageLogo,
  LastDataContainer,
  DisabilityContainer,
  ImgGPTW,
  ColMobile,
} from './mixins'
import { planConfig } from '../../constants/planConfig'
import features from '../../../../constants/features-per-country'
import { SITE_ID } from '../../../../constants'

const gptwEnabled = features[SITE_ID || 'BMAR'].gptw.enabled

const Component = ({ aptoDiscapacitado, tipoAviso, mobileFormat, gptwUrl, isDesktop }) => {
  const showGptw = gptwEnabled && !!gptwUrl
  const isMobileFormat = !isDesktop || mobileFormat
  const tipoAvisoCross = planConfig[tipoAviso].isAvisoZonaJobs || planConfig[tipoAviso].isAvisoBumeran

  const HighlightedType = () => {
    if (!planConfig[tipoAviso].showHighlight) return null

    return (
      <>
        {tipoAvisoCross ? (
          <InterPageLogo
            src={planConfig[tipoAviso].isAvisoZonaJobs ? logoZonajobs : logoBumeran}
            alt="logo Zonajobs"
            aptoDiscapacitado={aptoDiscapacitado}
            mobileFormat={mobileFormat}
          />
        ) : (
          <></>
        )}
      </>
    )
  }

  return (
    <LastDataContainer mobileFormat={mobileFormat}>
      <LastInfoCol
        mobileFormat={mobileFormat}
        aptoDiscapacitado={aptoDiscapacitado}
        crossSite={planConfig[tipoAviso].isAvisoZonaJobs || planConfig[tipoAviso].isAvisoBumeran}
      >
        {aptoDiscapacitado && (
          <DisabilityContainer
            hasHighlight={planConfig[tipoAviso].showHighlight}
            mobileFormat={mobileFormat}
            crossSite={planConfig[tipoAviso].isAvisoZonaJobs || planConfig[tipoAviso].isAvisoBumeran}
          >
            <DataIcon name="icon-light-discapacity" size="16" color="#666674" />
            <DataInfoDisability>Apto discapacidad</DataInfoDisability>
          </DisabilityContainer>
        )}

        {!isMobileFormat && showGptw ? <ImgGPTW src={logoGPTW} alt="logo GPTW" /> : <HighlightedType />}
      </LastInfoCol>

      {isMobileFormat && !tipoAvisoCross && (
        <ColMobile>{showGptw && <ImgGPTW src={logoGPTW} alt="logo GPTW" />}</ColMobile>
      )}
    </LastDataContainer>
  )
}

export default Component

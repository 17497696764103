import styled from 'styled-components'

export const PageContent = styled.div``


export const Title = styled.h2`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    font-size: 18px;
  }
`

export const SubTitle = styled.p`
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 12px;
`

export const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.grey24};
  margin-top: 8px;
  margin-bottom: 12px;
  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    margin-top: 10px;
  }
`

export const RequeridData = styled.p`
  color: ${({ theme }) => theme.colors.grey60};
  font-size: 14px;
  line-height: 20px;
  width: 286px;
  &::after {
    content: ' *';
    color: ${({ theme }) => theme.colors.alternativeError};
  }
`

export const FormGroup = styled('div')`
  margin-bottom: 4px;
  &:nth-child(2) {
    margin-bottom: 48px;
  }
`

export const LabelInput = styled.p`
  font-size: 14px;
  margin-bottom: 4px;
  line-height: 20px;
  display: inline-block;
`

import styled from 'styled-components'

export const ModalContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 16px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    margin-top: -32px;
    margin-bottom: -14px;
  }
`

export const ModalImg = styled.img`
  width: 173px;
`

export const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  text-align: center;
  color: ${({ theme }) => theme.colors.secondary.normal};
  margin: 10px 0;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    font-size: 24px;
    line-height: 32px;
    margin: 24px 0;
  }
`

export const ModalText = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grey84};

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    font-size: 16px;
    line-height: 20px;
  }
`

export const ModalSelection = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  gap: 10px;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    gap: 16px;
  }
`

export const ModalCheck = styled.div`
  padding: 10px;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  margin-top: 34px;

  button {
    min-width: 190px;
  }

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    flex-direction: row;
    justify-content: center;
    margin-top: 16px;
  }
`

export const LoaderContainer = styled.div`
  text-align: center;
  padding: 200px 0;
`

import React, { useContext, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'styled-components'

// uikit
import { MainContainer, Row } from '@navent-jobs/ui-kit/components/grid'
import Loader from '@navent-jobs/ui-kit/components/loader/Loader'
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { PopOverComponent } from '@navent-jobs/ui-kit/components/pop-over'
import { useWindowSize } from '@navent-jobs/ui-kit/hooks/window-size'

// style
import {
  Wrapper,
  ImageContainer,
  Spacer,
  Divider,
  FooterContainer,
  PaddingSpacer,
  DividerContainer,
  ImageResult,
  DataContainer,
  GeneralCol,
  GeneralContainer,
  DetailCol,
  SectionTitle,
  OpinionContainer,
  GeneralTitle,
  CategoryTitle,
  WantsToWorkContainer,
  CustomRow,
  Text,
  PopoverContainer,
} from './mixins'

// constants
import features from '../../../../constants/features-per-country'
import { SITE_ID } from '../../../../constants'
import calificarImg from '../../../../constants/calificar-img'

// components
import AccesoCalificarEmpresa from '../../../acceso-calificar-empresa'
import General from '../calificacion-general'
import DetalleGeneral from '../detalle-general'
import Categorias from '../categorias'
import Recomendaciones from '../recomendaciones'
import CalificarFooter from '../calificar-footer'
import Opinion from '../opinion'

const CATEGORY_GENERAL_ID = 1

const Component = ({
  idCompany,
  denominacion,
  showCommentSection = false,
  reviews,
  applicantReview,
  loading,
  name,
  isFichaAviso,
  mobileView = false,
}) => {
  const { t } = useTranslation()
  const { isMobile } = useWindowSize()
  const { colors } = useContext<{ colors }>(ThemeContext)
  const [showPopover, setShowPopover] = useState(false)
  const showComments = applicantReview && showCommentSection && features[SITE_ID || 'BMAR'].reviews.comments.enabled
  const showWantsToWork = features[SITE_ID || 'BMAR'].reviews.wantsToWork.enabled

  const categories = reviews?.categories?.filter(p => p.categoryId !== CATEGORY_GENERAL_ID)
  const showCategories = categories?.length > 0

  const smallView = mobileView || isMobile

  const PopoverWrapper = ({ children }) =>
    smallView ? (
      <PopoverContainer isFichaAviso={isFichaAviso}>
        <PopOverComponent
          id="wantsToWork-popOver"
          visible={showPopover}
          onClose={() => setShowPopover(false)}
          content={t('review.result.wantsToWorkPopover', { quantity: reviews?.wantsToWorkThere })}
          arrowProps="lowerRight"
          autoHide={false}
          fullwidth
        >
          {children}
        </PopOverComponent>
      </PopoverContainer>
    ) : (
      <>{children}</>
    )

  if (!reviews || reviews?.totalEmployeeReviews < 3) {
    return <AccesoCalificarEmpresa idCompany={idCompany} isFichaAviso={isFichaAviso} mobileView={mobileView} />
  }

  return (
    <Wrapper>
      <MainContainer noGutter>
        <PaddingSpacer mobileView={mobileView} isFichaAviso={isFichaAviso} showMiddleContent={showCategories}>
          <CustomRow mobileView={mobileView} isFichaAviso={isFichaAviso}>
            <GeneralTitle>{t('review.result.generalReviewTitle')}</GeneralTitle>
            {showWantsToWork && reviews?.wantsToWorkThere > 3 && (
              <PopoverWrapper>
                <WantsToWorkContainer
                  onClick={() => setShowPopover(true)}
                  onMouseEnter={() => setShowPopover(true)}
                  onMouseLeave={() => setShowPopover(false)}
                >
                  <Icon name="icon-light-heart" size="16" color={colors.grey84} />
                  <Text>
                    {smallView
                      ? reviews?.wantsToWorkThere
                      : t('review.result.wantsToWork', { quantity: reviews?.wantsToWorkThere })}
                  </Text>
                </WantsToWorkContainer>
              </PopoverWrapper>
            )}
          </CustomRow>
          <Row noGutter>
            <DataContainer mobileView={mobileView} isFichaAviso={isFichaAviso}>
              <GeneralContainer mobileView={mobileView}>
                <GeneralCol mobileView={mobileView}>
                  <General
                    mobileView={mobileView}
                    category={reviews?.categories?.find(c => c.categoryId === CATEGORY_GENERAL_ID)}
                  />
                </GeneralCol>
                <DetailCol>
                  <DetalleGeneral category={reviews?.categories?.find(c => c.categoryId === CATEGORY_GENERAL_ID)} />
                </DetailCol>
              </GeneralContainer>
              {showCategories && (
                <>
                  <Spacer mobileView={mobileView}>
                    <Row noGutter>
                      <>
                        <Divider />
                        <CategoryTitle mobileView={mobileView}>{t('review.result.categoryReviewTitle')}</CategoryTitle>
                        <Categorias mobileView={mobileView} categories={categories} />
                      </>
                    </Row>
                  </Spacer>

                  <Row noGutter>
                    <Recomendaciones
                      labelKey="review.result.recommendsPercentage"
                      percentage={(reviews?.recommended * 100) / reviews?.totalEmployeeReviews}
                    />
                  </Row>
                </>
              )}
            </DataContainer>
            {!isFichaAviso && (
              <ImageContainer mobileView={mobileView}>
                <ImageResult src={calificarImg.resultado} alt="resultados" width="100%" />
              </ImageContainer>
            )}
          </Row>
        </PaddingSpacer>

        {showComments && (
          <>
            <DividerContainer>
              <Divider />
            </DividerContainer>

            <SectionTitle>{t('review.comments.applicantTitle')}</SectionTitle>

            <OpinionContainer mobileView={mobileView}>
              <Opinion name={name} applicantReview={applicantReview} />
            </OpinionContainer>
          </>
        )}

        <DividerContainer>
          <Divider />
        </DividerContainer>
        <FooterContainer mobileView={mobileView}>
          {loading ? (
            <Loader></Loader>
          ) : (
            <CalificarFooter applicantReview={applicantReview} nameCompany={denominacion} idCompany={idCompany} />
          )}
        </FooterContainer>
      </MainContainer>
    </Wrapper>
  )
}

const mapStateToProps = ({ reviewStore, applicantStore }) => ({
  reviews: reviewStore.reviews,
  applicantReview: reviewStore.applicantReview,
  loading: reviewStore.states.loading,
  name: applicantStore.applicant?.nombre,
})

export default connect(mapStateToProps)(Component)

export const colorPalletForPructs = {
  bumeran: {
    aviso: {
      freemium: '#29293D',
      talento: '#29293D',
      simple: '#00DCD4',
      destacado: '#0A26EE',
      home: '#FAB306',
      tecnologia: '#E90066',
      zona: '#FF652B',
    },
    bbd: '#D9005C',
    pda: '#3D47F5',
    hunter: '#0D164E',
    conecta: '#8E3DF5',
    express: '#6700B8',
  },
  zonajobs: {
    aviso: {
      freemium: '#585992',
      select: '#47B0E2',
      clasic: '#FF652B',
      gold: '#F1D40D',
      platinum: '#959595',
      tecnologia: '#3B76CE',
    },
    bbd: '#646464',
  },
}

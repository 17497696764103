import styled from 'styled-components'

export const LastDataContainer = styled('div')`
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  scroll-behavior: smooth;

  ${({ mobileFormat, theme }) =>
    !mobileFormat &&
    `@media (min-width: ${theme.screen.xlMin}) {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }`}
`

export const LastInfoCol = styled<{ isAvisoZonaJobs: boolean; aptoDiscapacitado: boolean }>('div')`
  height: 100%;
  margin-top: 4px;
  display: flex;
  align-items: center;
  ${({ aptoDiscapacitado }) => (!aptoDiscapacitado ? 'justify-content: flex-end' : 'justify-content: space-between')};

  ${({ mobileFormat, theme, crossSite, aptoDiscapacitado }) =>
    !mobileFormat &&
    `@media (min-width: ${theme.screen.xlMin}) {
    margin-top: 0px;
    flex-direction: column;
    align-items: flex-end;
    ${crossSite && !aptoDiscapacitado ? 'justify-content: flex-start' : 'justify-content: flex-end'};
  }`}
`

export const DisabilityContainer = styled<{ hasHighlight: boolean; crossSite: boolean }>('div')`
  display: flex;
  align-items: center;
  ${({ crossSite }) => crossSite && 'order: 1'}

  ${({ mobileFormat, theme, crossSite, hasHighlight }) =>
    !mobileFormat &&
    `@media (min-width: ${theme.screen.xlMin}) {
    ${crossSite || !hasHighlight ? 'margin-bottom: 0px' : 'margin-bottom: 14px'};
  }`}
`

export const DataInfoDisability = styled('h3')`
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.grey60};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const ImgGPTW = styled<{ aptoDiscapacitado: boolean }>('img')`
  width: 16px;
  height: 26px;
`

export const InterPageLogo = styled<{ aptoDiscapacitado: boolean }>('img')`
  max-width: 71px;
  height: 20px;
  display: inline-block;
  order: 1;

  ${({ mobileFormat, theme, aptoDiscapacitado }) =>
    !mobileFormat &&
    `@media (min-width: ${theme.screen.xlMin}) {
    order: 0;
    ${!aptoDiscapacitado ? 'margin-bottom: 0px' : 'margin-bottom: 14px'};
  }`}
`

export const ColMobile = styled('div')`
  margin-top: 4px;
  height: 24px;
`

import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  width: 100%;
  background-color: white;
  border-radius: 8px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    justify-content: flex-start;
  }
`

export const GeneralContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  ${({ mobileView, theme }) =>
    mobileView
      ? ''
      : `
    @media (min-width: ${theme.screen.mdMin}) {
      flex-wrap: inherit;
    }
  `}
`

export const Spacer = styled.div`
  margin-top: 16px;
  margin-bottom: 4px;

  ${({ theme, mobileView }) =>
    mobileView
      ? ''
      : `
    @media (min-width: ${theme.screen.mdMin}) {
      margin-top: 0px;
      margin-bottom: 10px;
    }
  `}
`

export const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.grey24};
  width: 100%;
`

export const DividerContainer = styled.div`
  margin-left: 12px;
  margin-right: 12px;

  @media (min-width: ${({ theme }) => theme.screen.mdMin}) {
    margin-left: 16px;
    margin-right: 16px;
  }
`

export const FooterContainer = styled.div`
  padding: 16px;
  text-align: center;

  ${({ mobileView, theme }) =>
    mobileView
      ? ''
      : `
    @media (min-width: ${theme.screen.mdMin}) {
      padding: 24px;
    }
  `}
`

export const PaddingSpacer = styled.div`
  padding: 12px 12px 16px;

  ${({ mobileView, theme, showMiddleContent, isFichaAviso }) =>
    mobileView
      ? ''
      : `
    @media (min-width: ${theme.screen.mdMin}) {
      padding: 32px 32px ${showMiddleContent ? '32px' : '0px'} 32px;
    }
  
    @media (min-width: ${theme.screen.xlMin}) {
      padding: 32px ${isFichaAviso ? '32px' : '0px'} 32px 32px;
    }
  `}
`

export const ImageResult = styled.img`
  max-width: 240px;
`

export const DataContainer = styled.div`
  width: 100%;

  ${({ mobileView, theme, isFichaAviso }) =>
    mobileView
      ? ''
      : `
    @media (min-width: ${theme.screen.xlMin}) {
      width: ${isFichaAviso ? '100%' : '71%'};
    }
  `}
`

export const ImageContainer = styled.div`
  display: none;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    ${({ mobileView }) =>
      mobileView
        ? ''
        : `
        width: 29%;
        display: initial;
        padding-left: 16px;
        padding-right: 24px;
    `}
  }
`

export const GeneralCol = styled.div`
  max-width: 160px;
  margin: auto;

  ${({ mobileView, theme }) =>
    mobileView
      ? ''
      : `
    @media (min-width: ${theme.screen.mdMin}) {
      margin-right: 24px;
    }
  `}
`

export const DetailCol = styled.div`
  width: 100%;
`

export const GeneralTitle = styled.h3`
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.grey84};
  margin: 0 0 16px 0;
`

export const CategoryTitle = styled.h3`
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.grey84};
  margin: 16px 0 16px 0;

  ${({ theme, mobileView }) =>
    mobileView
      ? ''
      : `
    @media (min-width: ${theme.screen.xlMin}) {
      margin: 24px 0 16px 0;
    }
  `}
`

export const SectionTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  margin: 24px 0 0 16px;
  color: ${({ theme }) => theme.colors.secondary.normal};
`

export const OpinionContainer = styled.div`
  ${({ mobileView, theme }) =>
    mobileView
      ? `
      width: 100%;
      padding: 16px;
      `
      : `
      width: 50%;
      padding: 16px;

      @media (max-width: ${theme.screen.smMin}) {
        width: 100%;
      }
    `}
`

export const CustomRow = styled(DataContainer)`
  display: flex;
  justify-content: space-between;
`

export const WantsToWorkContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px;
  height: 100%;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.secondary.ultraLighten};
`

export const Text = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`

export const PopoverContainer = styled.div`
  cursor: pointer;
  ${({ theme, isFichaAviso }) =>
    !isFichaAviso &&
    `
    #popover-wantsToWork-popOver > div {
      @media ((min-width: ${theme.screen.lgMin} ) and (max-width: ${theme.screen.xlMin})) {
        width: calc(324px - 10vw);
      }

      @media ((min-width: ${theme.screen.xlMin} ) and (max-width: 1390px)) {
        width: calc(324px - 4vw);
      }
    }`}
`

import styled from 'styled-components'

export const PreguntasContainer = styled('div')`
  font-size: 12px;
  line-height: 16px;
  width: 100%;
  padding: 8px;
  margin: 0 0 12px 0;
  border: 1px solid ${({ theme }) => theme.colors.grey24};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: ${({ isLoading }) => (isLoading ? 'center' : 'left')};
  justify-content: center;
  color: ${({ theme }) => theme.colors.secondary.normal};
  cursor: pointer;

  @media (min-width: ${({ theme }) => theme.screen.xlMin}) {
    width: 314px;
    margin: 0 16px 0 0;
  }
`

export const TitleWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const TitleContent = styled('div')`
  display: flex;
  justify-content: left;
`

export const Title = styled('p')`
  font-weight: 600;
  margin-bottom: 0;
  margin-left: 4px;
  color: ${({ theme }) => theme.colors.alternativeError};
`

export const Subtitle = styled('p')`
  font-weight: 400;
  margin: 0;
  color: ${({ theme }) => theme.colors.grey60};
`

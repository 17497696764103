import React, { useEffect, useCallback, useContext } from 'react'
// tools
import { Controller, useForm } from 'react-hook-form'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'styled-components'
import { debounce } from 'lodash'
// components
import { Icon } from '@navent-jobs/ui-kit/components/icon'
import { Input } from '@navent-jobs/ui-kit/components/input'
import { ContainerFluid, Row, Col } from '@navent-jobs/ui-kit/components/grid'
import { showSnackbar, showSnackbarError } from '../../../../redux/actions/applicant/curriculum/snackbar'
import { SkeletonUpdateEmail } from '../common/skeleton/UpdateEmail'

// redux
import { validateEmail } from '../../../../redux/actions/email'
import { updateEmail } from '../../../../redux/actions/applicant/account/updateEmail'
import { getApplicantSummary } from '../../../../redux/actions/applicant/applicant'
import { UpdateEmailState } from '../../../../redux/types/applicant/account/updateEmail'
import { SITE_ID } from '../../../../constants'

// styles
import {
  Content,
  Header,
  FormGroup,
  SubTitle,
  RequiredTitle,
  RightCol,
  LeftCol,
  CustomSubmitButton,
  CustomLabel,
  ChageWarning,
  WarningWrapper,
  ChageBlueWarning,
} from './mixins'
import { Title, Divider } from '../../mixins'

const mailformat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

interface UpdateEmailProps {
  updateEmailSuccess: boolean
  updateEmailError: boolean
  updateEmailLoading: boolean
  isLogged: boolean
  userEmail: string
  statusCode: UpdateEmailState['statusCode']
}

const UpdateEmail = ({
  updateEmailSuccess,
  updateEmailError,
  updateEmailLoading,
  isLogged,
  userEmail,
  statusCode,
}: UpdateEmailProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { colors } = useContext<{ colors }>(ThemeContext)
  // Inicializamos React Hook Form
  const {
    control,
    handleSubmit,
    formState,
    formState: { errors },
    setError,
    getValues,
    reset,
    clearErrors,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  // Handler - Validacion del email ----------------------------------
  const handlerValidateUserEmail = email => dispatch(validateEmail(email))

  // validacion si el mail ingresado cumple con el formato de ser mail Y si ya se encuentra en uso
  const validateDuplicatedEmail = (value: string) => {
    if (userEmail === value) {
      clearErrors('email')
      setError('email', {
        type: 'pattern',
        message: t('account.updateEmail.validations.equal'),
      })
    }

    if (!mailformat.test(value)) {
      clearErrors('email')
      setError('email', {
        type: 'pattern',
        message: t('account.updateEmail.validations.invalid'),
      })
    }
    if (userEmail !== value) {
      handlerValidateUserEmail(value)
    }
  }

  const handleValidateDuplicatedEmail = useCallback(
    debounce(() => validateDuplicatedEmail(getValues('email')), 500),
    [userEmail]
  )

  const actionSuccess = () => {
    dispatch(showSnackbar(t('account.updateEmail.snackbarMessage.success'), 'icon-light-save'))
    dispatch(getApplicantSummary())
    reset({ password: '', email: '' })
  }

  useEffect(() => {
    if (updateEmailSuccess) {
      if (statusCode === 'SUCCESS') {
        actionSuccess()
      } else {
        clearErrors('password')
        if (statusCode === 'EMAIL_UNDER_USE') {
          setError('email', {
            type: 'pattern',
            message: t('account.updateEmail.validations.underUse'),
          })
        }

        if (statusCode === 'INVALID_PASSWORD')
          setError('password', {
            type: 'pattern',
            message: t('account.updateEmail.validations.wrongPass'),
          })
      }
    } else if (updateEmailError) {
      dispatch(showSnackbarError(t('account.snackbarMessage.error')))
    }
  }, [updateEmailSuccess, updateEmailError])

  // Submit del formulario
  const onSubmit = dataForm => {
    dispatch(updateEmail(dataForm))
  }

  if (!isLogged) {
    return <SkeletonUpdateEmail />
  }

  return (
    <Content>
      <Header id="cambiar-email">
        <Title>{t('account.updateEmail.title')}</Title>
        <Divider />
        <SubTitle>{t('account.updateEmail.subTitle')}</SubTitle>
        <WarningWrapper>
          <Icon name="icon-light-alert-triangle" size="20" color={colors.alternativeError} />
          <ChageWarning>{t('account.updateEmail.warning')}</ChageWarning>
        </WarningWrapper>
        <RequiredTitle>{t('account.requiredTitle')}</RequiredTitle>
      </Header>
      <ContainerFluid>
        <Row>
          <LeftCol sm={6} noGutter>
            <FormGroup>
              <Input
                id="current-email"
                name="current-email"
                type="email"
                value={userEmail}
                placeholder="Email actual"
                disabled
                fieldOptions={{
                  before: <Icon name="icon-light-email" size="20" color={colors.secondary.normal} />,
                  label: <CustomLabel> {'Email actual'} </CustomLabel>,
                  variant: 'darken',
                  maxLength: 50,
                }}
                errors={{ ...errors }}
              />
            </FormGroup>
          </LeftCol>
        </Row>
        <form id="form-update-email" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <LeftCol sm={6} noGutter>
              <FormGroup>
                {/* Email */}
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: true,
                    pattern: mailformat,
                  }}
                  render={({ onChange, value }) => (
                    <Input
                      id="email"
                      type="text"
                      name="email"
                      value={value}
                      onChange={e => {
                        onChange(e)
                        handleValidateDuplicatedEmail()
                      }}
                      onBlur={() => {
                        handlerValidateUserEmail(value)
                      }}
                      rules={{ required: true }}
                      placeholder={t('account.updateEmail.newEmailPlaceholder')}
                      fieldOptions={{
                        before: <Icon name="icon-light-email" size="20" color={colors.secondary.normal} />,
                        label: <CustomLabel>{t('account.updateEmail.newEmailLabel')}</CustomLabel>,
                        variant: 'darken',
                        maxLength: 50,
                      }}
                      errors={{ ...errors }}
                    />
                  )}
                />
              </FormGroup>
            </LeftCol>
            <RightCol sm={6} noGutter>
              <FormGroup>
                {/* Password */}
                <Controller
                  control={control}
                  name="password"
                  rules={{ required: true }}
                  render={({ onChange, value }) => (
                    <Input
                      name="password"
                      id="password"
                      type="password"
                      fieldOptions={{
                        before: <Icon name="icon-light-lock" size="20" color={colors.secondary.normal} />,
                        label: <CustomLabel>Contraseña actual</CustomLabel>,
                        variant: 'darken',
                        maxLength: 50,
                      }}
                      value={value}
                      onChange={e => {
                        onChange(e)
                        clearErrors('password')
                      }}
                      errors={{ ...errors }}
                      placeholder={t('account.updateEmail.password')}
                      rules={{ required: true }}
                    />
                  )}
                />
              </FormGroup>
            </RightCol>
          </Row>
          <Row>
            <Col noGutter>
              {/* no mostramos este mensaje en Mexico */}
              {SITE_ID !== 'BMMX' && (
                <WarningWrapper>
                  <Icon name="icon-light-alert-circle" size="20" color={colors.secondary.normal} />
                  <ChageBlueWarning>{t('account.updateEmail.blueWarning')}</ChageBlueWarning>
                </WarningWrapper>
              )}
              <CustomSubmitButton
                idForm="form-update-email"
                isLoading={updateEmailLoading}
                disabled={!formState.isValid || updateEmailLoading}
              >
                {t('account.updateEmail.cta')}
              </CustomSubmitButton>
            </Col>
          </Row>
        </form>
      </ContainerFluid>
    </Content>
  )
}

const states = ({ updateEmailStore }) => {
  const { state, statusCode } = updateEmailStore
  return {
    updateEmailSuccess: state.success,
    updateEmailError: state.error,
    updateEmailLoading: state.loading,
    statusCode,
  }
}

export default connect(states)(UpdateEmail)

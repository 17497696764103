export default {
  snackbarNotificationSuccess: '¡Guardamos tus cambios!',
  snackbarNotificationError: 'Hubo un error, vuelve a intentarlo.',
  snackbarNotificationErrorArchivo: 'No pudimos cargar tu archivo.',
  snackbarNotificationDeleted: 'Archivo eliminado',
  snackbarReferenciaEliminada: 'Referencia eliminada',
  common: {
    phoneErrorText: 'Ingresa un teléfono válido.',
    emailInvalid: 'Ingresa un email válido.',
    linkInvalid: 'Ingresa una url válida.',
    autocomplete: 'Ingresa al menos un caracter',
    fechaNacError: 'Completa tu fecha de nacimiento',
    generoError: 'Selecciona tu género',
    paisError: 'Selecciona el país en el que vives',
    provinciaError: 'Selecciona la provincia en la que vives',
    localidadError: 'Selecciona la localidad en la que vives',
    direccionError: 'Escribe tu dirección',
    backButton: 'Volver',
    confirmButton: 'Guardar',
    confirmationModal: {
      cancelButton: 'Cancelar',
      deleteButton: 'Eliminar',
    },
  },
  form: {
    remainder: 'Campos obligatorios',
    save: 'Guardar',
    cancel: 'Cancelar',
  },
  datosContacto: {
    content: {
      sectionTitle: 'Datos de contacto',
      SinEspecificar: 'Sin especificar',
    },
    form: {
      emailTitle: 'Email',
      emailPlaceholder: 'Ingresa tu email',
      emailInvalid: 'Ingresa un email válido.',
      emailDuplicated: 'Este email ya está en uso.',
      paisTitle: 'País',
      paisPlaceholder: 'Elige una opción',
      provinciaTitle: 'Región',
      provinciaPlaceholder: 'Elige una opción',
      localidadTitle: 'Comuna',
      localidadPlaceholder: 'Elige una opción',
      direccionTitle: 'Dirección',
      direccionPlaceholder: 'Ingresa tu dirección',
      celularTitle: 'Teléfono celular',
      celularPlaceholder: 'Número',
      telefonoTitle: 'Otro teléfono',
      telefonoPlaceholder: 'Número',
      phoneErrorText: 'Ingresa un teléfono válido.',
      infoEmailChange: 'El mail de ingreso es el mismo que se les muestra a las empresas. Para cambiarlo, ingresa a ',
    },
  },
  completitudCv: {
    titleCvComplete: '¡Tu {{cv}} está completo!',
    titleCvIncomplete: 'Mejora tu {{cv}} agregando:',
    descriptionFoto: 'Foto',
    descriptionCelular: 'Teléfono celular',
    descriptionResidencia: 'Lugar de residencia ',
    descriptionExperienciasLaborales: 'Experiencia laboral',
    descriptionDatosPersonales: 'Datos personales',
    descriptionEstudios: 'Estudios',
    descriptionObjetivo: 'Objetivo',
    descriptionSalario: 'Preferencia salarial',
    descriptionVideo: 'Video presentación',
    descriptionIdiomas: 'Idiomas',
    descriptionConocimientosYHabilidades: 'Conocimientos y habilidades',
    descriptionPda: 'Test de personalidad',
  },
  conocimientosYHabilidades: {
    sectionTitle: 'Conocimientos y habilidades',
    addItem: 'Sumar conocimiento',
    listNameNormalizada: 'Informática',
    listNameDesNormalizada: 'Otros conocimientos',
    emptyStateDescription: 'Agrega tus conocimientos y habilidades',
    deleteTitle: '¿Quieres eliminar tu conocimiento o habilidad?',
    snackbarSkillEliminado: 'Conocimiento eliminado',
    form: {
      title: 'Sumar conocimientos y habilidades',
      remainingText: 'Tienes {{restantes}} conocimientos y habilidades para agregar',
      subtitle: 'Escribe una palabra simple o compuesta. Ej: Microsoft power point',
      conocimientoTitle: 'Conocimiento o habilidad',
      conocimientoPlaceholder: 'Ingresa al menos 2 caracteres',
    },
  },
  idiomas: {
    sectionTitle: 'Idiomas',
    addItem: 'Sumar idioma',
    emptyState: 'Agrega tu primer idioma',
    deleteIdioma: '¿Quieres eliminar tu idioma?',
    snackbarIdiomaEliminado: 'Idioma eliminado',
    form: {
      addTitle: 'Sumar idioma',
      editTitle: 'Editar idioma',
      genericPlaceholder: 'Elige una opción',
      idiomaTitle: 'Idioma',
      nivelOralTitle: 'Nivel oral',
      nivelEscritoTitle: 'Nivel escrito',
    },
  },
  educacion: {
    sectionTitle: 'Formación académica y cursos',
    addItemMain: 'Sumar estudio',
    addItemReference: 'Sumar referencia académica',
    addItemCertificado: 'Sumar certificado',
    emptyStateDescription: 'Agrega tu primer estudio',
    sinEstudiosOption: 'No tengo estudios',
    sinEstudiosDescription: 'No tengo estudios',
    sinEstudiosCta: 'Cargar mi primer estudio',
    emptyStateFirstJobOption: 'Busco mi primer trabajo',
    companero: 'compañero/a',
    content: {
      confirmada: 'REFERENCIA CONFIRMADA',
      rechazada: 'REFERENCIA RECHAZADA',
      sin_confirmar: 'REFERENCIA PENDIENTE',
    },
    deleteEstudio: '¿Quieres eliminar tu estudio?',
    snackbarEstudioEliminado: 'Estudio eliminado',
    snackbarCertificadoEliminado: 'Certificado de estudio eliminado',
    deleteReferenciaEstudio: '¿Quieres eliminar tu referencia académica?',
    deleteCertificadoEstudio: '¿Quieres eliminar tu certificado?',
    form: {
      titleAdd: 'Sumar estudio',
      titleEdit: 'Editar estudio',
      carreraTitulo: 'Título / Carrera',
      carreraTituloPlaceholder: 'Ingresa tu título o carrera',
      paisTitle: 'País',
      genericPlaceholder: 'Elige una opción',
      tipoEstudio: 'Tipo de estudio',
      areaEstudio: 'Área de estudio',
      estadoEstudio: 'Estado',
      institucion: 'Institución',
      institucionPlaceholder: 'Ingresa la institución',
      fechaInicio: 'Fecha de inicio',
      fechaFin: 'Fecha de finalización',
    },
    referencia: {
      addTitle: 'Sumar referencia académica',
      editTitle: 'Editar referencia académica',
      titleHeader: 'Estudio relacionado:',
      emailText: 'Tu referencia recibirá un email para confirmar sus datos.',
      nombrePlaceholder: 'Ingresa el nombre',
      nombreTitle: 'Nombre',
      apellidoPlaceholder: 'Ingresa el apellido',
      apellidoTitle: 'Apellido',
      emailPlaceholder: 'Ingresa el email',
      emailTitle: 'Email',
      telefonoPlaceholder: 'Número',
      telefonoTitle: 'Teléfono',
      relacionPlaceholder: 'Elige una opción',
      relacionTitle: 'Relación',
    },
    certificado: {
      title: 'Sumar certificado',
      certificadoCargado: 'Certificado cargado',
      emptyState: {
        description: 'Solo puedes elegir una opción',
        linkOption: 'Ingresa un link',
        fileOption: 'Sube un archivo adjunto',
        fileWarning: 'El archivo debe ser en formato pdf o jpeg/jpg y pesar menos de 2 MB.',
      },
      form: {
        label: 'Certificado',
        placeholder: 'Pega el link al certificado',
        error: 'Éste campo es obligatorio',
      },
    },
  },
  datosPersonales: {
    form: {
      genericPlaceholder: 'Elige una opción',
      nombreTitle: 'Nombre',
      nombrePlaceholder: 'Ingresa tu nombre',
      apellidoTitle: 'Apellido',
      apellidoPlaceholder: 'Ingresa tu apellido',
      paisNacimientoTitle: 'Nacionalidad',
      fechaNacimientoTitle: 'Fecha de nacimiento',
      estadoCivilTitle: 'Estado civil',
      estadoCivilPlaceholder: 'Estado civil',
      tipoDocTitle: 'Tipo de documento',
      tipoDocPlaceholder: 'Tipo',
      numeroDocumentoPlaceholder: 'Número',
      generoTitle: 'Género',
      licenciaTitle: 'Poseo licencia de conducir',
      movilidadTitle: 'Poseo movilidad propia',
      modalTitle: 'Datos personales',
      tipoLicenciaTitle: 'Selecciona el tipo de licencia',
    },
    content: {
      sectionTitle: 'Datos personales',
      sinEspecificar: 'Sin especificar',
      especificarTipo: 'especificar tipo',
      tieneLicencia: 'Sí',
      TieneVehiculo: 'Sí',
      Nacionalidad: 'Nacionalidad:',
      Nacimiento: 'Fecha de nacimiento:',
      Documento: 'Documento:',
      Genero: 'Género:',
      EstadoCivil: 'Estado civil:',
      Licencia: 'Lic. de conducir:',
      Movilidad: 'Movilidad propia:',
    },
    avatar: {
      titulo: 'Foto de perfil',
      elegirOtraFoto: 'Elegir otra foto',
      extensionesValidas: 'La imagen debe tener formato .jpg o .png',
      guardar: 'Guardar',
      cancelar: 'Cancelar',
      confirmar: 'Confirmar',
      modalError: {
        invalidFormat: 'No pudimos subir tu foto',
      },
    },
  },
  experienciaLaboral: {
    sectionTitle: 'Experiencia laboral',
    addItemMain: 'Sumar experiencia',
    addItemReference: 'Sumar referencia laboral',
    emptyStateDescription: 'Agrega tu primera experiencia laboral',
    emptyStateFirstJobOption: 'Busco mi primer trabajo',
    firstJobDescription: 'Estoy buscando mi primer trabajo',
    firstJobCta: 'Cargar mi primera experiencia',
    personaACargo: ' persona a cargo',
    personasACargo: ' personas a cargo',
    manejaPresupuestoAnual: 'Manejaba un presupuesto anual',
    deleteExperiencia: '¿Quieres eliminar tu experiencia laboral?',
    deleteReferenciaExperiencia: '¿Quieres eliminar tu referencia laboral?',
    snackbarExperienciaEliminado: 'Experiencia eliminada',
    alert: {
      modalTitle: 'Tienes {{ number }} experiencias actuales',
      modalDescription: 'No te olvides de completar la fecha de finalización de las experiencias que hayas finalizado.',
      cta: 'Aceptar',
    },
    referenceStatus: {
      confirmada: 'REFERENCIA CONFIRMADA',
      rechazada: 'REFERENCIA RECHAZADA',
      sin_confirmar: 'REFERENCIA PENDIENTE',
    },
    form: {
      titleAdd: 'Sumar referencia laboral',
      titleEdit: 'Editar referencia laboral',
      ingresaElNombre: 'Ingresa el nombre',
      nombre: 'Nombre',
      ingresaElApellido: 'Ingresa el apellido',
      apellido: 'Apellido',
      eligeUnaOpcion: 'Elige una opción',
      relacion: 'Relación',
      ingresaElMail: 'Ingresa el email',
      email: 'Email',
      telefono: 'Teléfono',
      numero: 'Número',
      confirmEmail: 'Tu referencia recibirá un email para confirmar sus datos.',
      experienciaRelacionada: 'Experiencia relacionada:',
      addTitle: 'Sumar experiencia laboral',
      editTitle: 'Editar experiencia laboral',
      comboPlaceholder: 'Elige una opción',
      empresaTitle: 'Empresa',
      empresaPlaceholder: 'Ingresa el nombre',
      actividadTitle: 'Actividad de la empresa',
      puestoTitle: 'Puesto',
      puestoPlaceholder: 'Ingresa el nombre',
      nivelTitle: 'Nivel de experiencia',
      areaTitle: 'Área del puesto',
      subareaTitle: 'Subárea',
      paisTitle: 'País de la empresa',
      fechaInicioTitle: 'Fecha de inicio',
      fechaFinTitle: 'Fecha de finalización',
      alPresenteTitle: 'Al presente',
      detalleTitle: 'Descripción de responsabilidades',
      detallePlaceholder: 'Escribe cuáles son tus tareas',
      personasACargoTitle: 'Personas a cargo',
      personasACargoPlaceholder: 'Ingresa un número',
      presupuestoTitle: 'Manejo de presupuesto anual propio',
    },
    formLocation: {
      title: 'Confirma tu dirección',
      cancelButton: 'Volver',
    },
  },

  discapacidad: {
    discapacidad: 'Discapacidad',
    helperText:
      'Si tienes alguna discapacidad, no es obligatorio que la declares. Pero si lo haces, te ayudará a encontrar puestos que mejor se adapten a tus necesidades.',
    tengoDiscapacidad: 'Agregar discapacidad',
    cargaArchivoFailed: 'No pudimos cargar tu archivo',
    borrarDiscapacidad: '¿Quieres borrar el archivo?',
    form: {
      archivosPermitidos: 'Puedes subir un archivo de hasta 3.5M en formato .doc, .docx, .pdf o .jpg.',
      cargarArchivo: 'Cargar certificado',
      discapacidadDescription: 'Describe qué tipo de discapacidad tienes',
      archivoInvalido:
        'No pudimos cargar tu archivo. Recuerda que solo puede tener formato .doc, .docx, .pdf o .jpg y que no debe superar los 3.5MB.',
    },
  },

  cvAdjunto: {
    title: '{{cv}} adjunto',
    subirArchivo: 'Subir {{cv}}',
    confirmDelete: '¿Quieres eliminar tu {{cv}} adjunto?',
    puedeSubirAdjunto: {
      helperText: 'Carga tu {{cv}} de hasta 3.5Mb en formato .doc, .docx o .pdf.',
    },
    noPuedeSubirAdjunto: {
      helperText: 'Si quieres subir un archivo, escribe al menos una experiencia laboral o un estudio.',
    },
    errorForm: {
      cargaArchivoFailed: 'No pudimos cargar tu archivo',
      archivoInvalido:
        'Recuerda que solo se puede subir un archivo en formato .doc, .docx, .pdf o .jpg y que no debe superar los 3.5MB.',
      subirOtroArchivo: 'Subir otro archivo',
    },
  },
  preferenciaSalarial: {
    sectionTitle: 'Preferencia salarial',
    addItem: 'Escribir preferencia salarial',
    salarioDescription: 'Sueldo bruto pretendido: ',
    emptyDescription: 'Cuéntanos cuánto quieres ganar',
    snackbarNotificationDeleted: 'Preferencia salarial eliminada',
    confirmDelete: '¿Quieres eliminar tu preferencia salarial?',
    form: {
      title: 'Preferencia salarial',
      label: '¿Cuánto quieres ganar?',
      salarioPlaceholder: 'Sueldo bruto pretendido',
      error: 'Éste campo es obligatorio',
    },
    salaryLabelInfo: 'Recuerda que el sueldo bruto es el salario total sin las retenciones e impuestos.',
  },
  objetivoLaboral: {
    addItem: 'Escribir objetivo laboral',
    sectionTitle: 'Objetivo laboral',
    emptyTitle: 'Cuéntanos cuáles son tus expectativas profesionales',
    snackbarNotificationDeleted: 'Objetivo laboral eliminado',
    confirmDelete: '¿Quieres eliminar tu objetivo laboral?',
    form: {
      title: 'Objetivo laboral',
      objetivoPlaceholder: 'Cuéntanos en pocas palabras',
      objetivoTitle: '¿Cuáles son tus expectativas a nivel profesional?',
    },
  },

  testPersonalidad: {
    sectionTitle: 'Test de personalidad',
    addItem: 'Hacer el test',
    toggleLabel: 'Mostrar a empresas',
    snackbarNotificationMostrar: 'Tu test está visible.',
    snackbarNotificationOcultar: 'Tu test está oculto.',
    emptyDescription:
      'Conoce más de tu perfil y permítele a las empresas que descubran los puntos fuertes de tu personalidad.',
    invalidDescription: 'No pudimos obtener un resultado con las respuestas que nos diste.',
    readMore: 'Leer completo',
    form: {
      title: 'Test de personalidad',
    },
  },
  videoPresentacion: {
    sectionTitle: 'Video presentación',
    addItem: 'Grabar video',
    confirmDelete: '¿Quieres eliminar tu video presentación?',
    emptyDescriptionBold: 'Graba un video',
    emptyDescription:
      'de 20 segundos contando un poco sobre vos. Resalta tus fortalezas y menciona todo lo que no aparece en tu {{cv}}.',
    example: 'Ver ejemplo',
    descriptionHeader: '¡Tu video quedó muy bien!',
    descriptionWidthVideo: 'Las empresas lo usarán para conocerte más allá de tu {{cv}}.',
    info: 'Puedes volver a grabarlo las veces que quieras.',
    snackbarNotificationSuccess: 'Estamos procesando tu video',
    snackbarNotificationDeleted: 'Video eliminado',
  },
  legales:
    'es responsable por la Base de Datos registrada bajo el número 1022 ante la Dirección Nacional de Protección de Datos Personales. El titular de los datos personales tiene la facultad de ejercer el derecho de acceso a los mismos en forma gratuita a intervalos no inferiores a seis meses, salvo que se acredite un interés legítimo al efecto, conforme artículo 14 de la Ley 25.326. Puede hacerlo personalmente o por correo postal a la dirección Arévalo 1880, piso 3º, C.A.B.A. (C1414BGX), Argentina. La Dirección Nacional de Protección de Datos Personales, Órgano de Control de la Ley Nro. 25.326, tiene la atribución de atender las denuncias y reclamos que se interpongan con relación al cumplimiento de las normas sobre Protección de Datos Personales.',
  metaData: {
    metaTitle1: 'Empleos en',
    metaTitle2: 'trabajo y ofertas de empleo -',
    descriptionBuscaEmpleo: 'Busca empleos en',
    descriptionBolsaEmpleo: 'en la bolsa de trabajo más grande del país. ',
    descriptionEncuentraOfertas: 'Encuentra ofertas de empleo y trabajos en las mejores empresas en',
  },
  onboarding: {
    bienvenidaButton: '¡Vamos!',
    continueButton: 'Guardar y continuar',
    omitirButton: 'Omitir este paso',
    irInicioButton: 'Volver al inicio',
    replaceContentButton: 'Reemplazar contenido',
    replacePictureButton: 'Reemplazar foto',
    keepCurrentContent: 'Mantener contenido previo',
    steps: {
      bienvenida: {
        titlePart1: 'Te damos la bienvenida a',
        titleLogin: '¿Nos cuentas un poco más sobre ti?',
        titleQrPart1: '¡Ya enviamos tus datos a la empresa!',
        titleQrPart2: 'Ahora, ¿nos cuentas un poco más sobre ti?',
        description: 'Para ayudarte a encontrar el trabajo que buscás, necesitamos conocerte un poco mejor. ',
        info: 'Podrás revisar y editar tus datos desde el {{cv}}.',
      },
      trabajo: {
        title: '¿De qué te gustaría trabajar?',
        subtitle: '¡Escribe el puesto que te interese!',
        inputLabel: 'Puesto',
        inputDescription: 'Escribe el puesto que te interese',
        helperText: 'Ingresa al menos 2 caracteres.',
        selectLabel: 'Lugar',
        selectDescription: 'Elige una opción',
        continueButton: 'Continuar',
      },
      modalidadCargaDeDatos: {
        title: '¡Hola, {{nombre}}!',
        titleQr: '¡Ya enviamos tus datos a la empresa! Ahora, termina de completar tu perfil',
        subtitle: '¿Quieres cargar tu {{cv}} a partir de un archivo o de forma manual?',
        lecturaIa: 'Lectura automática con IA',
        opcionAutomatica: 'Subir mi {{cv}}',
        opcionManual: 'Cargar mi {{cv}} manualmente',
        infoOpcionAutomatica:
          'Tu perfil se autocompletará con inteligencia artificial a partir del archivo que cargues. Tendrás la posibilidad de verificar la información y editarla en caso de ser necesario.',
        infoOpcionManual:
          'Te guiaremos paso a paso por las secciones del perfil y completarás la información de forma manual.',
        linkedinRegistro: 'Puedes descargar tu {{cv}} de Linkedin y subirlo en el próximo paso',
      },
      cvParcer: {
        volverAlCv: 'Volver al {{cv}}',
        parcerInfo: 'La conversión del {{cv}} puede tardar hasta 1 minuto.',
        uploadFile: {
          title: 'Selecciona el archivo de tu {{cv}}',
          info: 'Debe ser en formato .pdf, y no debe superar los 2 MB.',
        },
        uploadError: {
          title: 'Tu archivo no es válido',
          info: 'Recuerda que solo se puede subir un archivo en formato .pdf, y no debe superar los 2 MB.',
          cta1: 'Seleccionar otro archivo',
        },
        uploadSuccess: {
          title: 'Seleccionaste este archivo',
          cta1: 'Reemplazar',
          cta2: 'Eliminar',
          check: 'Quiero usar este archivo como mi {{cv}} adjunta',
        },
        dataExtractionSuccess: {
          title: '{{nombreUsuario}}, ya está todo listo para extraer el contenido de tu archivo',
          subTitle: 'Te pedimos que lo revises y, si encuentras algún error, puedes corregirlo.',
          fotoCheck: 'Dejar mi {{cv}} sin foto',
          continueButton: 'Continuar',
        },
        convertFile: 'Convertir {{cv}}',
      },
      experiencia: {
        emptyParse: 'No encontramos ninguna experiencia laboral',
        noTieneExperienciaChoice: 'Busco mi primer empleo',
        tieneExperienciaChoice: 'Sí, tengo experiencia',
        sinExperienciaDesc:
          'Si estás buscando tu primer trabajo, te invitamos a pasar a la siguiente pantalla para seguir completando tu {{cv}}. ¡Mucha suerte!',
        genericTitle: '¿Tienes experiencia laboral?',
        manualTitle: '¿Tienes experiencia laboral?',
        experienciaListTitle: 'Tus experiencias laborales',
        tieneExperienciaTitle: 'Ingresa tu estudio más reciente',
        form: {
          titleAdd: 'Sumar experiencia laboral',
          titleEdit: 'Editar experiencia laboral',
          empresaEmpty: 'Ingresa el nombre de la empresa',
          actividadEmpresaEmpty: 'Selecciona la actividad de la empresa',
          puestoEmpty: 'Ingresa tu puesto en la empresa',
          nivelEmpty: 'Selecciona tu nivel de experiencia',
          areaEmpty: 'Selecciona el área del puesto',
          subAreaEmpty: 'Selecciona la subárea del puesto',
          paisEmpty: 'Selecciona el país',
        },
        card: {
          label: {
            nivel: 'Nivel de experiencia: ',
            area: 'Área del puesto: ',
            subarea: 'Subárea del puesto: ',
            empresa: 'Empresa: ',
            actividad: 'Actividad de la empresa: ',
            pais: 'País de la empresa: ',
            fechaInicio: 'Fecha de inicio: ',
            fechaFin: 'Fecha de finalización: ',
            descripcion: 'Descripción: ',
            agregarDescripcion: 'Sumar descripción del puesto',
          },
          experienciaEnCurso: 'Al presente',
        },

        parser: {
          experienciaListTitle: 'Revisa tus experiencias laborales',
          addTitle: 'Agregar experiencia',
          pluralGenericError: 'Completa las {{emptyFieldsNumber}} experiencias que tienen datos faltantes.',
          genericError: 'Completa la experiencia que tiene datos faltantes.',
          experienciaReemplazar:
            'Puedes optar por mantener la información que ya tenías cargada previamente o reemplazarla por este nuevo contenido.',
        },
      },
      idioma: {
        title: 'Revisa tus idiomas',
        addTitle: 'Sumar idioma',
        skipTitle: 'Omitir este paso',
        emptyState: 'Agrega tu primer idioma',
        idiomasReemplazar:
          'Puedes optar por mantener la información que ya tenías cargada previamente o reemplazarla por este nuevo contenido.',
        form: {
          agregarText: 'Agregar',
          modificarText: 'Aceptar',
        },
      },
      educacion: {
        genericTitle: 'Cuéntanos qué estudios tienes',
        manualTitle: '¿Tienes estudios?',
        educacionListTitle: 'Tus estudios',
        emptyParse: 'No encontramos ningún estudio.',
        tieneEducacionChoice: 'Agregar un estudio',
        educacionDescripcion: 'Puedes sumar diferentes tipos: secundario, cursos, etc.',
        noTieneEducacionChoice: 'No tengo estudios para agregar',
        tieneEducacionTitle: 'Ingresa tu estudio más reciente',
        sinEducacionDesc:
          'Si no tienes estudios, te invitamos a pasar a la siguiente pantalla para seguir completando tu {{cv}}. ¡Mucha suerte!',
        form: {
          titleEdit: 'Editar estudio',
          titleAdd: 'Sumar estudio',
          tituloEmpty: 'Ingresa un título / carrera',
          paisEmpty: 'Selecciona el país',
          tipoEstudioEmpty: 'Selecciona el tipo de estudio',
          areaEstudioEmpty: 'Ingresa el área de estudio',
          institucionEmpty: 'Selecciona una institución',
          estadoEmpty: 'Selecciona el estado',
        },
        card: {
          label: {
            area: 'Área de estudio: ',
            tipo: 'Tipo de estudio: ',
            pais: 'País: ',
            institucion: 'Institución: ',
            estado: 'Estado: ',
            fechaInicio: 'Fecha de inicio: ',
            fechaFin: 'Fecha de finalización: ',
          },
          estudioEnCurso: 'Al presente',
        },
        parser: {
          educacionListTitle: 'Revisa tus estudios',
          addTitle: 'Sumar estudio',
          pluralGenericError: 'Completa los {{emptyFieldsNumber}} estudios que tienen datos faltantes.',
          genericError: 'Completa el estudio que tiene datos faltantes.',
          estudiosReemplazar:
            'Puedes optar por mantener la información que ya tenías cargada previamente o reemplazarla por este nuevo contenido.',
        },
      },
      conocimientos: {
        title: '¿Cuáles son tus conocimientos y habilidades?',
        titleParse: 'Revisa tus conocimientos y habilidades',
        titleParseEmpty: 'Completa tus conocimientos y habilidades',
        emptyParse: 'No encontramos conocimientos y habilidades, pero puedes cargarlos manualmente',
        conocimientosReemplazar:
          'Puedes optar por mantener la información que ya tenías cargada previamente o reemplazarla por este nuevo contenido.',
      },
      objetivoLaboral: {
        title: 'Revisa tu objetivo laboral',
        subtitle: 'Generamos este objetivo laboral a partir del contenido de tu {{cv}}, pero puedes editarlo.',
        placeholder: '¿Cuáles son tus expectativas laborales?',
      },
      datosPersonales: {
        title: '¿Cuáles son tus datos personales?',
        parser: {
          title: 'Revisa tus datos personales',
          datospersonalesReemplazar:
            'Puedes optar por mantener la información que ya tenías cargada previamente o reemplazarla por este nuevo contenido.',
        },
      },
      finalizar: {
        creacionCvtitle: '¡Creaste tu {{cv}} con éxito!',
        actualizarCvtitle: '¡Actualizaste tu {{cv}} con éxito!',
        description: 'Si quieres modificar tus datos o agregar nuevos, hazlo desde tu {{cv}}.',
        buttonCv: 'Ir a mi {{cv}}',
        buttonListadoAvisos: 'Ver avisos',
        buttonAviso: 'Continuar',
      },
      skipModal: {
        title: '¿Quieres omitir este paso?',
        subTitle: 'Si lo omites, no se guardarán los datos que cargaste.',
        cancel: 'Cancelar',
        skipStep: 'Omitir paso',
      },
    },
  },
  descargaCv: {
    button: 'Descargar mi {{cv}}',
    modalSidebar: {
      title: 'Descarga tu {{cv}}',
      subtitle: 'Selecciona el diseño que te guste para descargar tu {{cv}}.',
      buttonCancelar: 'Cancelar',
      buttonDescargar: 'Descargar',
    },
  },
  marketplace: {
    title: {
      product: {
        purchased: 'Productos adquiridos',
        alternativo: 'Productos adquiridos para resaltar tu {{cv}}',
        benefits: 'Nuestros beneficios',
      },
    },
    products: {
      nulinga: {
        course: {
          claim: {
            default: 'Curso de inglés con todas las comodidades que necesitas',
          },
        },
        claim: {
          default: 'Certifica tu nivel de inglés con un rápido cuestionario',
        },
      },
      pda: {
        claim: {
          completed: 'Descarga el resultado de tu test de personalidad completo',
        },
      },
      lablab: {
        claim: {
          completed: 'Descarga el informe de tu CV',
        },
      },
      doctorcv: {
        claim: {
          completed: 'Ver informe de tu CV',
        },
      },
      coderhouse: {
        claim: {
          default: 'Aprovecha un 20% de descuento en cualquier curso o carrera',
        },
      },
    },
    menu: 'Potencia tu perfil',
    claim: {
      text: 'Si quieres conocer más productos y beneficios para potenciar tu perfil,',
      link: 'haz click aquí',
    },
    modalProductInfo: {
      nulinga: {
        title: 'Potencia tu CV con Nulinga',
        paragraph1a:
          'Certifica tu nivel de inglés con un rápido cuestionario de selección múltiple. El resultado será analizado por los profesores de',
        paragraph1b: 'Nulinga, los expertos en programas de idioma.',
        paragraph2: 'Serás redireccionado al test una vez que lo hayas abonado a través de Mercado pago.',
        paragraph2b: 'El resultado aparecerá en la sección de idiomas en menos de 48 horas.',
        paragraph3: 'Recordá usar el e-mail registrado en Bumeran para hacer el test.',
        buttonAccept: 'Ir a Mercado Pago',
        buttonCancel: 'Cancelar',
      },
      miguru: {
        title: 'Analiza tu CV con MiGuru',
        paragraph1a: 'MiGuru lee tu Currículum',
        paragraph1b:
          'y te da recomendaciones personalizadas para conseguir la mejor versión posible y aumentar las oportunidades de ser llamado a tu próxima entrevista laboral.',
        paragraph1c: 'Es importante que tu currículum esté completo para sacarle un mayor provecho.',
        paragraph2a: 'Para ver una previsualización de tu reporte, haz',
        paragraph2b: 'click aquí',
        paragraph3: 'Compartiremos los datos de tu {{cv}} con MiGuru',
        buttonAccept: 'Ir a Mercado Pago',
        buttonCancel: 'Cancelar',
      },
      doctorcv: {
        title: 'Evalúa tu curriculum con Doctor CV',
        paragraph1a: 'Doctor CV',
        paragraph1b:
          'es una herramienta que realiza un diagnóstico de tu currículum, brindándote un informe detallado con recomendaciones para mejorarlo y aumentar las posibilidades de encontrar el empleo que buscas.',
        paragraph1c: 'Es importante que tu currículum esté completo para sacarle el mayor provecho.',
        paragraph2a: 'Para ver una previsualización de tu reporte, haz',
        paragraph2b: 'click aquí',
        paragraph3: 'Compartiremos los datos de tu {{cv}} con Doctor Cv',
        buttonAccept: 'Ir a Mercado Pago',
        buttonCancel: 'Cancelar',
      },
    },
    modalCvIncompleto: {
      title: 'Completa tu CV',
      paragrapha: 'Para acceder a este producto, debes registrar ',
      paragraphb: 'todos los campos solicitados en la sección de educación y experiencia de tu Currículum,',
      paragraphc:
        'ya que de esta manera vas a poder adquirir un informe completo con recomendaciones para mejorar tu CV.',
      buttonAccept: 'Aceptar',
    },
    accessMarketplace: {
      title: 'Productos y beneficios',
      claim: '¡Potencia tu perfil!',
    },
  },
  expoKonzerta: {
    title: '7 y 8 de septiembre en el Centro de Convenciones ATLAPA',
    titleSubscibed: 'Ya te inscribiste.',
    cta: '¡Inscríbete gratis!',
  },
  bannerOnboardingAccess: {
    modal: {
      title: '¿Quieres reemplazar la información que tienes en tu {{cv}} por la del nuevo archivo?',
      subtitle:
        'Te iremos mostrando sección por sección y podrás elegir entre mantener la información previa o reemplazarla por la del nuevo archivo.',
      labelConfirm: 'Continuar',
      labelCancel: 'Cancelar',
    },
    new: {
      title: 'Completa tu {{cv}} con IA',
      subtitle: '¡Tu {{cv}} está incompleto! Súbelo en pdf y nuestra IA lo autocompletará.',
    },
    update: {
      title: 'Actualiza tu {{cv}} con IA',
      subtitle: 'Carga tu {{cv}} en pdf y nuestra Inteligencia Artificial actualizará la información.',
      cta: 'Actualizar {{cv}}',
    },
  },
}
